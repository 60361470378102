.jssocials-shares {
  margin: 0.2em 0; }

.jssocials-shares * {
  box-sizing: border-box; }

.jssocials-share {
  display: inline-block;
  vertical-align: top;
  margin: 0.3em 0.6em 0.3em 0; }

.jssocials-share:last-child {
  margin-right: 0; }

.jssocials-share-logo {
  width: 1em;
  vertical-align: middle;
  font-size: 1.5em; }

img.jssocials-share-logo {
  width: auto;
  height: 1em; }

.jssocials-share-link {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  line-height: 1; }
  .jssocials-share-link.jssocials-share-link-count {
    padding-top: .2em; }
    .jssocials-share-link.jssocials-share-link-count .jssocials-share-count {
      display: block;
      font-size: .6em;
      margin: 0 -.5em -.8em -.5em; }
  .jssocials-share-link.jssocials-share-no-count {
    padding-top: .5em; }
    .jssocials-share-link.jssocials-share-no-count .jssocials-share-count {
      height: 1em; }

.jssocials-share-label {
  padding-left: 0.3em;
  vertical-align: middle; }

.jssocials-share-count-box {
  display: inline-block;
  height: 1.5em;
  padding: 0 0.3em;
  line-height: 1;
  vertical-align: middle;
  cursor: default; }
  .jssocials-share-count-box.jssocials-share-no-count {
    display: none; }

.jssocials-share-count {
  line-height: 1.5em;
  vertical-align: middle; }
